export default {
  BUTTON_PRIMARY_CLASS: 'button button--primary',
  BUTTON_SECONDARY_CLASS: 'button button--primary',
  SEARCH_BOX_PLACEHOLDER: 'Search by Keyword or Part #',

  FACET_INPUT_CLASS: 'form-input',

  GARAGE_TITLE: '',
  GARAGE_SIZE: '<span class="garage-size countPill countPill--positive" key="garage-size">{{size}}</span>',

  SEARCH_BOX_INPUT_CLASS: 'form-input',
  SEARCH_BOX_BUTTON_CLASS: 'search-icon submit search-submit btn-search',
};
