//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-488:_6252,_6636,_4816,_9188,_1856,_9852,_2992,_6836;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-488')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-488', "_6252,_6636,_4816,_9188,_1856,_9852,_2992,_6836");
        }
      }catch (ex) {
        console.error(ex);
      }